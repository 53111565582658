footer {
  @include full-bar($grey-light);
  padding-bottom: 30px;
  color: $grey-dark;
}

.footer {
  &-logo {
    @include susy-breakpoint($s) {
      display: none;
    }
    @include span(2);
    margin-top: 25px;
    padding-right: 20px;
    img {
      width: 100%;
    }
  }

  &-list {
    @include susy-breakpoint($s) {
      @include span(full);
      text-align: center;
    }
    @include span(2.5);

    h3 {
      margin-top: 20px;
      font-weight: normal;
      text-transform: uppercase;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    a {
      color: $grey-dark;
      text-decoration: none;
    }
  }
}

.copyright {
  @include container();
  @include full-bar($blue-light);
  padding-top: 20px;
  padding-bottom: 20px;
  text-align: center;
  color: $grey-dark;
}

.to-top {
  width: 100%;
  text-align: center;

  svg {
    fill: $blue-std;
    width: 40px;
    height: 40px;
    margin: 10px;
  }
}