.phase {
  margin-top: 50px;
  &-title {
    @include span(full);
    text-align: center;
    @include rem(font-size, 32px);
    color: $grey-dark;
  }

  &-callout {
    @include susy-breakpoint($s) {
      @include rem(font-size, 32px);
    }
    margin-top: 30px;
    @include span(full);
    text-align: center;
    @include rem(font-size, 44px);
    color: $blue-std;
    font-weight: 600;
  }

  &-image {
    margin-top: 30px;
    @include span(full inside no-gutters);

    img {
      width: 100%;
    }

    &-left, &-right {
      @include span(5);
    }

    &-center {
      @include span(2);
    }
  }

  &-text {
    @include susy-breakpoint($s) {
      @include rem(font-size, 18px);
    }
    @include span(full);
    text-align: center;
    @include rem(font-size, 44px);
    color: $blue-std;
    font-weight: 600;

    svg {
      @include susy-breakpoint($s) {
        height: 30px;
        width: 40px;
      }
      height: 60px;
      width: 100px;
      vertical-align: middle;
    }
  }

  &-more {
    @include span(full);
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
  }
}