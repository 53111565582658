@font-face {
  font-family: 'texgyreadventorbold';
  src: url('/site/css/fonts/texgyreadventor-bold-webfont.eot');
  src: url('/site/css/fonts/texgyreadventor-bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('/site/css/fonts/texgyreadventor-bold-webfont.woff') format('woff'),
  url('/site/css/fonts/texgyreadventor-bold-webfont.ttf') format('truetype'),
  url('/site/css/fonts/texgyreadventor-bold-webfont.svg#texgyreadventorbold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'texgyreadventorregular';
  src: url('/site/css/fonts/texgyreadventor-regular-webfont.eot');
  src: url('/site/css/fonts/texgyreadventor-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('/site/css/fonts/texgyreadventor-regular-webfont.woff') format('woff'),
  url('/site/css/fonts/texgyreadventor-regular-webfont.ttf') format('truetype'),
  url('/site/css/fonts/texgyreadventor-regular-webfont.svg#texgyreadventorregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
