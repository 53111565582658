/* Susy grid system */
$susy: (
        flow: ltr,
        math: fluid,
        output: float,
        gutter-position: split,
        container: 1080px,
        container-position: center,
        columns: 12,
        gutters: 20/60,
        column-width: 175px,
        global-box-sizing: border-box,
        last-flow: to,
  //debug: (
  //        image: show,
  //        color: rgba(#66f, .25),
  //        output: overlay,
  //        toggle: top right,
  //),
        use-custom: (
                background-image: true,
                background-options: false,
                box-sizing: true,
                clearfix: false,
                rem: true,
        )
);