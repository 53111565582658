.intro {
  margin-top: 50px;
  text-align: center;
  color: $grey-dark;

  &-swiss {
    @include susy-breakpoint($s) {
      @include span(full);
    }
    @include span(5 inside no-gutters);

    &-img {
      @include span(12);

      img {
        width: 100%;
      }
    }

    &-text {
      @include span(12);
      margin-top: -20px;

      p {
        margin: 0;
      }

      svg {
        width: 45px;
        height: 50px;
      }
    }
  }

  &-brochure {
    @include susy-breakpoint($s) {
      @include span(full);
    }
    @include span(7);

    h1 {
      @include rem(font-size, 32px);
      font-family: $font-sans;
      font-weight: normal;
      color: $blue-std;

      span {
        color: $grey-dark;
        font-size: 90%;
      }
    }

    .button {
      margin-top: 50px;
      font-size: 120%;
    }
  }
}