//Fonts
$font-sans: 'texgyreadventorregular', sans-serif;
$font-sans-bold: 'texgyreadventorbold', sans-serif;

//Kleuren
$white: #fff;
$black: #000;

$blue-std: #009fe3;
$blue-medium: #66ccff;
$blue-light: #e0f2fd;

$grey-dark: #656565;
$grey-medium: #dbdddf;
$grey-light: #f0f2f4;

$error: #FFE4E0;
$red: #CB1600;

//Breakpoints
$s: (max-width: 768px);
$m: (max-width: 1024px);