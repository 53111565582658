@import "susy";
@import "susyset";
@import "normalize-css";
@import "fonts";
@import "vars";
@import "../../config/sass/onlinq-lib";

@import "header";
@import "main";
@import "footer";
@import "intro";
@import "form";
@import "system";
@import "phase";
@import "worldwide";

* {
  box-sizing: border-box;
}

body {
  font-family: $font-sans;
}

section, main, footer {
  @include container();
}

.flipped {
  transform: scaleX(-1);
  filter: FlipH;
}