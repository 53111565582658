form {
  color: $grey-dark;

  ul{
    display: table;
    margin: 0;
    list-style: none;
    width: 100%;
    padding: 0;
  }
  li{
    display: table-row;
    > input,
    > select,
    > textarea,
    > label,
    > .row{
      display: table-cell;
      vertical-align: text-top;
      box-sizing: border-box;
      text-align: left;
      @include rem(font-size, 13px);

      > label {
        display: block;

        input[type=radio] {
          margin-right: 5px;
        }
      }

      &:first-child{
        padding-top: 1em;
        padding-right: 1em;
        width: 33%;
      }
      &:last-child{
        width: 100%;
      }
    }
  }

  button {
    margin-top: 20px;
    margin-bottom: 20px;
    float: right;
  }

  .formmanagement-response-node {
    @include span(full inside no-gutters);
    padding: 10px;

    &.success {
      background-color: $blue-light;
    }
    &.error {
      background-color: $error;
      border: 1px solid $red;
    }
  }
}
button, .button {
  background-color: $blue-std;
  border: none;
  padding: 10px 15px;
  color: $white;
  transition: .3s;
  text-decoration: none;

  &.bordered {
    box-shadow:inset 0px 0px 0px 2px $white;
  }

  &.light {
    background-color: $blue-medium;
  }

  &.wide {
    padding-left: 60px;
    padding-right: 60px;
  }
  &:hover {
    background-color: darken($blue-std, 10);
  }
}