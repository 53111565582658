.system {
  @include full-bar($blue-std);
  margin-top: 50px;
  padding-top: 30px;
  padding-bottom: 30px;

  &-text {
    @include susy-breakpoint($s) {
      @include span(full);
    }
    @include span(6 first);
    color: $white;

    span {
      @include rem(font-size, 32px);
      font-family: $font-sans;
      font-weight: normal;
    }

    .button {
      float: right;
      margin: 15px;
    }
  }

  &-img {
    @include susy-breakpoint($s) {
      @include span(full);
    }
    @include span(5 last);

    img {
      width: 100%;
      height: auto;
    }
  }
}