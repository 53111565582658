.worldwide {
  height: auto;

  &-text {
    @include susy-breakpoint($s) {
      @include span(full);
      text-align: center;
    }
    @include span(4);

    &-title {
      @include susy-breakpoint($s) {
        padding-top: 10px;
      }
      @include rem(font-size, 32px);
      padding-top: 60px;
      color: $blue-std;
    }

    &-subtitle {
      @include susy-breakpoint($s) {
        padding-top: 10px;
      }
      padding-top: 100px;
      padding-bottom: 30px;
      @include rem(font-size, 22px);
      color: $blue-std;
    }
  }

  &-line {
    @include span(full);
    height: 1px;
    @include full-bar($blue-std);
  }

  &-map {
    @include susy-breakpoint($s) {
      @include span(full);
      margin-top: 30px;
      text-align: center;
      
      > div {
        float: right;
      }
    }
    @include span(8);
  }
}