//Top header w/ language selection & contact
.header {
  @include container();
  &-bar {
    @include span(full inside no-gutters);
    @include full-bar($grey-light);
    height: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: $grey-dark;

    &-language {
      @include susy-breakpoint($s) {
        @include span(3);
      }
      @include span(4);

      a.current {
        color: $blue-std;
      }
    }

    &-contact {
      @include susy-breakpoint($s) {
        @include span(9);
      }
      @include span(8);
      text-align: right;

      a {
        margin-left: 30px;
      }

      &-mail {
        padding-left: 30px;
        border-left: 1px solid $grey-dark;
      }

      &-menu {
        display: none;
      }

      @include susy-breakpoint($s) {
        span {
          display: none;
        }

        &-mail {
          padding: 0;
          border: none;
        }

        &-menu {
          display: inline;
          margin-left: 30px;
        }
      }
    }

    a {
      display: inline-block;
      color: $grey-dark;
      text-decoration: none;
      transition: .5s;

      &:hover {
        color: $black;
        svg {
          fill: $blue-std;
        }
      }
    }

    svg {
      fill: $grey-dark;
      width: 30px;
      height: 30px;
      transition: .5s;
      vertical-align: middle;
    }
  }
}

//Divider bar
.divider {
  &-grey {
    @include full-bar($grey-medium);
    height: 1px;
  }
  &-blue {
    background-image: url('/site/images/clouds.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    height: 100px;
  }
}

//Logo & navigation
.navigation {
  @include susy-breakpoint($s) {
    margin-top: 5px;
    height: auto;
  }
  @include span(full inside no-gutters);
  margin-top: 20px;
  height: 75px;

  &-wrapper {
    @include container();
  }

  &-logo {
    @include susy-breakpoint($s) {
      @include span(full);
      text-align: center;
      margin-top: 30px;
    }
    @include span(3 first);

    img {
      width: 165px;
    }
  }

  &-menu {
    @include susy-breakpoint($s) {
      @include span(full);
      height: auto;

      nav {
        display: none;

        &.open {
          display: block;
        }
      }
    }

    @include span(9 last);
    height: 75px;

    nav {
      padding-top: 20px;
      position: relative;
    }

    ul {
      @include susy-breakpoint($s) {
        display: block;
      }
      position: relative;
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      justify-content: space-between;


      li {
        @include susy-breakpoint($s) {
          text-align: center;
          border-bottom: 1px solid $blue-medium;
          @include rem(font-size, 16px);
          line-height: 50px;
        }

        color: $grey-dark;
        transition: .3s;
        cursor: pointer;

        a {
          color: $grey-dark;
          text-decoration: none;
          &:hover {
            color: $blue-std;
          }
        }

        &:hover {
          color: $blue-std;
          ul {
            display: block;
          }
        }

        &.current {
          a {
            color: $blue-std;
          }
        }

        &.submenu {
          border: none;
        }

        ul {
          @include susy-breakpoint($s) {
            position: relative;
            display: block;
            top: 0;
          }
          position: absolute;
          top: 24px;
          display: none;
          z-index: 1;

          li {
            @include susy-breakpoint($s) {
              @include span(full inside no-gutters);
              border-bottom: 1px solid $white;
              margin: 0;
              padding: 0;

              &:last-child {
                border-bottom: 1px solid $blue-medium;
              }
            }
            @include rem(font-size, 14px);
            background-color: $blue-light;
            padding: 10px;
            margin-left: -10px;
            width: 200px;
          }
        }
      }
    }
  }
}

