$base-font-size: 16px;
@function strip-units($number) {
	@return $number / ($number * 0 + 1);
}
 
// rem calculation
@mixin rem($property, $value){
	#{$property}: $value;
	#{$property}: strip-units($value) / strip-units($base-font-size) * 1rem;
}

@mixin bp($var){
	$size: breakpoints($var);
	@include susy-breakpoint((max-width: map-get($size, "max-width")), map-get($size, "layout")) {
		@content;
	}
}

@mixin cf{
	&:after{
		content: "";
		display: table;
		clear: both;
	}
}

// Creates a background on the full width of the screen
@mixin full-bar($color, $direction: both){
	background: $color;
	&:before {
		@if($direction == both){
			border-left: 9999px solid $color;
			box-shadow: 9999px 0 0 $color;
		} @else if($direction == left){
			border-left: 9999px solid $color;
		} @else if($direction == right){
			box-shadow: 9999px 0 0 $color;
		} @else {
			@warn "@mixin full-bar only supports a direction to left, right or to both";
		}
	}
	@extend %full-bar;
}

%full-bar{
	position: relative;
	&:before{
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: -9990px;
		right: 0;
		z-index: -1;
	}
}