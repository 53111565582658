main {
  margin-top: 30px;
  padding-top: 30px;
  border-top: 1px solid $blue-std;
}
.main {
  &-content {
    @include span(full);
    padding-bottom: 30px;
    color: $grey-dark;
    
    a {
      color: $blue-std;
      font-weight: bold;
      text-decoration: none;
    }

    h1 {
      @include susy-breakpoint($s) {
        text-align: center;
      }
      color: $blue-std;
      margin-top: 0;
    }
    &.half {
      @include susy-breakpoint($s) {
        @include span(full);
      }
      @include span(7);
    }

    ul {
      padding-left: 20px;
    }

    img:nth-child(odd) {
      float:right;
    }

    img:nth-child(even) {
      float:left;
    }

    table {
      @include susy-breakpoint($s) {
        font-size: 80%;

        td {
          padding: 5px;

          img {
            width: 100%;
          }
        }
      }
    }
  }

  &-form {
    @include susy-breakpoint($s) {
      @include span(full);
    }
    @include span(5);
  }

  &-image {
    @include susy-breakpoint($s) {
      @include span(full);
    }
    @include span(5);
    margin-bottom: 30px;

    img {
      max-width: 100%;
      height: auto;
    }
  }
}